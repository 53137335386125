// we do not have an i18n instance to use in the shared directory
// but we will still export the locales for use through this file

import { aviary } from "@aviary/locales";

import * as sharedLocales from "./en/US";

const l = { aviary, ...sharedLocales };

export { l };
